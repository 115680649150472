import React from 'react';
import './ScheduledAssessment.css'
const ScheduledAssessment = () => {
    return (
        <div>
          Scheduled Assessment
        </div>
    );
};

export default ScheduledAssessment;